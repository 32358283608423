<template>
<div>
  <div class="row">
    <div class="col-sm-24">
      <v-radio-group v-model="arbeitgeber" row label="Wo sind Sie beschäftigt?*" :hide-details="true">
        <v-radio hide-details label="St.-Clemens-Hospital" value="St.-Clemens-Hospital"  off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details label="MVZ Gelderland" value="MVZ Gelderland"  off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details label="Gelderland-Klinik" value="Gelderland-Klinik " off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details label="Gesundheitszentrum" value="Gesundheitszentrum"  off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </div>
  </div>

  <div class="row" v-if="arbeitgeber == 'Gesundheitszentrum'">
    <div class="col-sm-24">
      <contact-input name="arbeitgeber_info" label="Wo sind Sie beschäftigt?"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-sm-6">
      <contact-select name="salutation" label="Anrede" group="contact" values="Frau,Herr" placeholder="Anrede"></contact-select>
    </div>
    <div class="col-sm-9">
      <contact-input name="firstname" label="Vorname" group="contact"></contact-input>
    </div>
    <div class="col-sm-9">
      <contact-input name="lastname" label="Nachname" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-sm-10">
      <contact-input name="street" label="Straße, Hausnummer" group="contact"></contact-input>
    </div>
    <div class="col-sm-6">
      <contact-input name="zip" label="PLZ" group="contact"></contact-input>
    </div>
    <div class="col-sm-8">
      <contact-input name="city" label="Ort" group="contact"></contact-input>
    </div>
  </div>


  <div class="row row-small">
    <div class="col-sm-8">
      <contact-input name="email" label="E-Mail" group="contact"></contact-input>
    </div>

    <div class="col-sm-8">
      <contact-input name="phone" label="Telefon" group="contact"></contact-input>
    </div>

    <div class="col-sm-8">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateFormatted"
            label="Beginn der Nutzung*"
            readonly
            required
            filled
            outlined
            @blur="date = parseDate(dateFormatted)"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          @change="$refs.dialog.save(date)"
          :min="minDate" v-model="date" type="month" scrollable>
          <span v-if="isLowMindate" class="text-xsmall px-1">Ist der Nutzungsbeginn für Sie zu spät? Bitte wenden Sie sich an unser
              <a href="https://www.stadtwerke-geldern.de/kontakt/" target="_blank">Kundenzentrum</a>.</span>
        </v-date-picker>
      </v-dialog>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-24">
      <contact-input textarea label="Nachricht" name="nachricht"></contact-input>
    </div>
  </div>

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsFlexparken",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    arbeitgeber: "St.-Clemens-Hospital",
    tarif: "flexparken",
    date: null,
    modal: false
  }),

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    },

    dateFormatted() {
      if (this.date) {
        return this.formatDate(this.date);
      }

      return "";
    },

    isLowMindate() {
      let now = new Date();
      let oneDay = 1000 * 60 * 60 * 24;
      let nextmonth = new Date(now.getFullYear(), now.getMonth() + 1, 2);
      let daysLeft = Math.ceil((nextmonth.getTime() - now.getTime()) / oneDay);
      if (daysLeft <= 15) {
        return true;
      }

      if (daysLeft <= 7) {
        return true;
      }

      return false;
    },

    minDate() {
      let now = new Date();
      let addMonths = 1;
      if (this.isLowMindate) {
        addMonths = 2;
      }

      let date = new Date(now.getFullYear(), now.getMonth() + addMonths, 2).toISOString();

      return date.substr(0, 10);
    },

    error() {
      return this.$root.contactForm.error;
    }
  },

  watch: {
    date () {
    },
  },

  mounted() {
    this.$root.contactForm.registerField(this);
    this.date = this.minDate;
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month] = date.split('-')
      return `${month}.${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}`
    },

    mapValue() {
      this.$root.contactForm.dataFields.arbeitgeber = this.arbeitgeber;
      this.$root.contactForm.dataFields.beginn = this.dateFormatted;
      this.$root.contactForm.dataFields.tarif = this.tarif;
    }
  }
};
</script>
