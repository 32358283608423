import Vue from "vue";
import vuetify from "../vuetify.js";
import depsloader from "../depsloader";
import DauerparkenApp from "../components/parken/DauerparkenApp.vue";

export default function() {
  const element = document.querySelector("#vue-dauerparken");

  if (element) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      new Vue({
        el: element,

        components: {
          DauerparkenApp
        },

        vuetify,
        i18n: new vueI18n.VueI18n(vueI18n.options),

        render: h => h(DauerparkenApp)
      })
    });
  }
}
