<template>
  <v-tabs v-model="tab" class="elevation-0 immo-expose__tabs">
    <v-tabs-slider></v-tabs-slider>

    <v-tab v-for="i in tabs" :key="i.anchor_name" :href="`#t${i.anchor_name}`">
      {{ i.title }}
    </v-tab>

    <v-tab-item v-for="i in tabs" :key="i.anchor_name" :value="'t' + i.anchor_name">
      <v-card :outlined="true">
        <v-card-text>
          <div class="mb-1">
            {{ i.content }}
          </div>

          <div
            v-if="i.iframe_url"
            :ref="'iframe-container-' + i.anchor_name"
            v-html="i.iframe_url"
          ></div>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  name: "ExposeTab",

  data() {
    return {
      tab: null,
    };
  },

  computed: {
    tabs() {
      return this.$root.tabs;
    },
  },

  methods: {
    loadIframes() {
      this.$nextTick(() => {
        this.tabs.forEach((tab) => {
          if (tab.iframe_url) {
            const container = this.$refs[`iframe-container-${tab.anchor_name}`];
            if (container && container[0]) {
              this.injectScripts(container[0]);
            } else {
              setTimeout(() => {
                const retryContainer = this.$refs[`iframe-container-${tab.anchor_name}`];
                if (retryContainer && retryContainer[0]) {
                  this.injectScripts(retryContainer[0]);
                } else {
                  //   console.warn(`Container für ${tab.anchor_name} nicht gefunden`);
                }
              }, 100);
            }
          }
        });
      });
    },

    injectScripts(container) {
      const scripts = container.getElementsByTagName("script");
      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement("script");
        Array.from(script.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(script.innerHTML));
        script.parentNode.replaceChild(newScript, script);
      });
    },

    checkAnchor() {
      const hash = window.location.hash.substring(1);
      const matchingTab = this.tabs.find((tab) => tab.anchor_name === hash);
      if (matchingTab) {
        this.tab = "t" + matchingTab.anchor_name;
      }
    },
  },

  mounted() {
    this.loadIframes();
    this.checkAnchor();
  },

  updated() {
    this.loadIframes();
  },
};
</script>
