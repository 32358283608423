<template>
<div>
    <slot></slot>
</div>
</template>

<script>
export default {
  name: "Mainnavigation",

  mounted() {
  },

  methods: {
    isDropdownDisabled() {
      if (this.navbarToggler) {
        // hamburger button is visible
        if (this.navbarToggler.offsetParent) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
