<template>
<v-app>
  <v-expansion-panels
    v-model="panel">
    <div class="row" v-if="content.length > 0">
    <div :class="columns"
      v-for="(item,i) in content"
      :key="i"
    >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="h2" v-if="icon"><i :class="icon"></i></span>
        <span class="h2" v-else>{{ i + 1 }}</span>
        <span class="lead">{{ item.title }}</span>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div v-html="item.content"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </div>
    </div>
    <div v-else>
      <h2>Zurzeit sind keine Stellenausschreibungen verfügbar.</h2>
    </div>
  </v-expansion-panels>
</v-app>
</template>

<script>
export default {
  name: "Accordion",

  data() {
    return {
      panel: null
    }
  },

  mounted() {
    for (var i in this.content) {
      if (window.location.hash == "#" + this.content[i].title) {
        this.panel = parseInt(i);
      }
    }
  },

  computed: {
    content() {
      return this.$root.content;
    },
    icon() {
      return this.$root.icon;
    },
    columns() {
      if (this.$root.onecolumn) {
        return "col-24 col-lg-18 offset-lg-3";
      }

      return "col-24 col-lg-12";
    }
  },
}
</script>
