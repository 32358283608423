<template>
<div data-app>
<v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline bg-primary w-100"
        primary-title
      >
          Dauerparken
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <div class="pt-2">
        <contact-form
            :name="name"
            :required-fields="requiredFields"
            buttonText="Parkkarte kostenpflichtig bestellen"
            submitUrl="/wp-json/sw-parken/v1/entries">
            <contact-fields-dauerparken></contact-fields-dauerparken>
        </contact-form>
        </div>
      </v-card-text>
    </v-card>
</v-dialog>
</div>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFieldsDauerparken from "./ContactFieldsDauerparken";

export default {
  name: "DauerparkenApp",

  components: {
    ContactForm,
    ContactFieldsDauerparken
  },

  data() {
    return {
      dialog: false,
      name: "dauerparken"
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#" + this.name + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = true;
      });
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    authenticationToken() {
      if (this.$store && this.$store.state) {
        return this.$store.state.auth.authenticationToken;
      }

      return null;
    },

    loggedIn() {
      if (this.$store && this.$store.state) {
        return this.$store.state.user.loggedIn;
      }

      return false;
    },

    exclude() {
      let ignoreFields = [];
      if (this.loggedIn) {
        ignoreFields = ["email"];
      }

      let el = [];
      if (this.$root.exclude) {
        el = this.root.exclude;
      }

      return ignoreFields.concat(el);
    }
  }
};
</script>
