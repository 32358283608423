<template>
<v-app>
  <form action="/" method="GET" ref="form">
      <v-text-field
        v-model="term"
        :append-outer-icon="'fas fa-search'"
        name="s"
        clearable
        placeholder="Suchbegriff"
        type="text"
        outlined
        filled
        @click:append-outer="sendMessage"
        @click:clear="clearMessage"
      ></v-text-field>
  </form>
</v-app>
</template>

<script>

export default {
  name: "Searchform",

  data: () => ({
    term: null,
  }),

  mounted() {
    this.term = this.$root.term;
  },

  methods: {
    sendMessage () {
      this.$refs.form.submit();
    },
    clearMessage () {
      this.term = ''
    },
  },
};
</script>
